var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("html", { attrs: { lang: "en" } }, [
      _vm._m(0),
      _c("body", [
        _c("div", { attrs: { id: "notfound" } }, [
          _c(
            "div",
            { staticClass: "notfound" },
            [
              _vm._m(1),
              _c("h1", [_vm._v("Cảnh báo!")]),
              _c("h2", [_vm._v("Không tìm thấy trang")]),
              _c("br"),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.goBack()
                    },
                  },
                },
                [_vm._v("Về trang chủ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("head", [
      _c("meta", { attrs: { charset: "utf-8" } }),
      _c("meta", {
        attrs: { "http-equiv": "X-UA-Compatible", content: "IE=edge" },
      }),
      _c("meta", {
        attrs: {
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
      }),
      _c("title", [_vm._v("404 HTML Template by Colorlib")]),
      _c("link", {
        attrs: {
          href: "https://fonts.googleapis.com/css?family=Quicksand:700",
          rel: "stylesheet",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "notfound-bg" }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }